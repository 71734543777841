import { CssBaseline, ThemeProvider } from '@mui/material';
import Lenis from '@studio-freight/lenis';
import React, { useContext, useEffect } from 'react';

import type { ThemeContextType } from '../../../../context/themeContext/theme.Context.types';
import { ThemeContext } from '../../../../context/themeContext/themeContext';
import { themeDark } from '../../../theme/rumblefish23Theme';
import { Footer } from '../Footer';
import { Header } from '../Header';
import type { LayoutProps } from './Layout.types';

export const Layout = ({ blogPosts, children }: LayoutProps) => {
  const { scrollTarget } = useContext(ThemeContext) as ThemeContextType;

  useEffect(() => {
    if (scrollTarget && document.body) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'auto';

    const lenis = new Lenis(
      !scrollTarget
        ? { lerp: 0.08 }
        : { smoothWheel: false, wrapper: scrollTarget as HTMLElement }
    );

    function raf(time: number) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, [scrollTarget]);

  return (
    <ThemeProvider theme={themeDark}>
      <CssBaseline>
        <Header blogPosts={blogPosts} />
        {children}
        <Footer />
      </CssBaseline>
    </ThemeProvider>
  );
};

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import linkedInIcon from '../../../assets/svg/linkedIn.svg';
import type { GalleryTeamProps } from './GalleryTeam.types';

export const GalleryTeam = ({ teamData }: GalleryTeamProps) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Typography
        sx={(theme) => ({
          ...theme.mixins.neutralGradient,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        })}
        variant="strong_700">
        Meet our leadership team
      </Typography>

      <Grid pt={6} item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          {teamData.map(({ img, name, position, linkedIn }) => (
            <Grid
              key={name}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              container
              direction="column"
              alignItems="center"
              gap={1}>
              <img
                style={{
                  borderRadius: '50%',
                  width: '250px', // Set the width to a fixed value
                  height: '250px', // Set the height to the same fixed value
                  objectFit: 'fill', // Ensure the image covers the box without distortion
                }}
                src={img}
                alt={name}
              />
              <Typography
                sx={{
                  color: (theme) => theme.palette.invertedContent?.SECONDARY,
                }}
                component="div"
                variant="h4">
                {name}{' '}
              </Typography>
              <Typography
                sx={{
                  color: (theme) => theme.palette.invertedContent?.SECONDARY,
                }}
                component="div">
                {position}
              </Typography>
              <a
                href={linkedIn}
                aria-label="linkenIn profile"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  style={{}}
                  src={linkedInIcon}
                  width="40"
                  height="40"
                  alt="linked icon"
                />
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { BlogPostSnippetProps } from './BlogPostSnippet.types';
import React from 'react';
import { NameBanner } from '../../../../Rumblefish23Theme';
import {
  AuthorsStack,
  SingleAuthorStack,
  AvatarImage,
  StyledAuthorName,
  StyledContent,
  StyledImage,
  StyledMainStack,
  StyledDesktopTechnologiesStack,
  StyledTitle,
} from './styles';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import { useTheme } from '@mui/material';
import { getPlainText } from '../utils/getPlainText';

export const BlogPostSnippet = ({
  mainImageSrc,
  authors,
  title,
  textContent,
  technologies,
  ...props
}: BlogPostSnippetProps): ReactJSXElement => {
  const isMobile = useTheme().utils.isMobile();

  return (
    <StyledMainStack {...props}>
      <StyledImage alt={`${title}_BlogPostImage`} src={mainImageSrc} />
      <StyledTitle variant="strong_600">{title}</StyledTitle>
      <AuthorsStack>
        {authors.map((author, index) => (
          <SingleAuthorStack key={author.id || index}>
            <AvatarImage
              alt={`${author.attributes?.Avatar?.data?.attributes?.name}_BlogPostAuthorAvatar`}
              src={author.attributes?.Avatar?.data?.attributes?.url || ''}
            />
            <StyledAuthorName variant="strong_200">
              {`By ${author.attributes?.Name}`}
            </StyledAuthorName>
          </SingleAuthorStack>
        ))}
      </AuthorsStack>
      <StyledContent variant="regular_300">
        {getPlainText(textContent)}
      </StyledContent>
      {isMobile ? (
        <ScrollContainer
          style={{
            width: '100%',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '8px',
          }}>
          {technologies.map((technology, index) => {
            return (
              <NameBanner
                type="element"
                key={index}
                text={technology?.name || ''}
              />
            );
          })}
        </ScrollContainer>
      ) : (
        <StyledDesktopTechnologiesStack>
          {technologies.map((technology, index) => {
            return (
              <NameBanner
                type="element"
                key={index}
                text={technology?.name || ''}
              />
            );
          })}
        </StyledDesktopTechnologiesStack>
      )}
    </StyledMainStack>
  );
};

import React from 'react';
import { NavigatorContent } from '../NavigatorContent';
import type { Ipage, NavigatorProps } from './Navigator.types';
import { NavigatorWrapper, StyledCurrentPageText } from './styles';

export const Navigator = ({ pages, currentPageName }: NavigatorProps) => {
  return (
    <NavigatorWrapper>
      <NavigatorContent page={{ name: 'Home', link: '/' }} />
      {pages.map((page: Ipage) => {
        return <NavigatorContent key={page.name} page={page} />;
      })}
      <StyledCurrentPageText variant="strong_400">
        {currentPageName}
      </StyledCurrentPageText>
    </NavigatorWrapper>
  );
};

import { ChatBoxRowProps } from './ChatBoxRowProps.types';
import React from 'react';
import parse from 'html-react-parser';
import {
  ImageSectionWrapper,
  MessageContentWrapper,
  RowWrapper,
  StyledAiAvatar,
  StyledImage,
  StyledImageText,
  StyledMessageText,
  StyledTimeText,
  TextWrapper,
} from './styles';

export const ChatBoxRow = ({
  message: { writerId, time, text, pic },
}: ChatBoxRowProps) => {
  const fromLeft = writerId === 1;

  return (
    <RowWrapper alignItems={fromLeft ? 'flex-start' : 'flex-end'}>
      <MessageContentWrapper
        justifyContent={fromLeft ? 'flex-start' : 'flex-end'}>
        {fromLeft && (
          <StyledAiAvatar>
            <StyledMessageText variant="strong_300">AI</StyledMessageText>
          </StyledAiAvatar>
        )}
        <TextWrapper>
          <StyledMessageText variant="regular_200">{text}</StyledMessageText>
          {pic && (
            <ImageSectionWrapper>
              <StyledImage src={pic.img} />
              <StyledImageText variant="regular_200">
                {parse(pic.desc)}
              </StyledImageText>
            </ImageSectionWrapper>
          )}
        </TextWrapper>
      </MessageContentWrapper>
      <StyledTimeText variant="regular_100">{time}</StyledTimeText>
    </RowWrapper>
  );
};

import { Stack, Typography, styled } from '@mui/material';
import type { CSSProperties } from 'react';

const multilineTruncate: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
};
export const StyledMainStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  overflow: 'hidden',
  gap: theme.spacing(2),
  flex: 1,
  width: theme.utils.fluidSize({
    minSize: 274,
    maxSize: 447,
    minBp: theme.breakpoints.values.md,
  }),
  [theme.breakpoints.down('md')]: {
    width: '274px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '447px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(3),
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyBackgroundColor?.SURFACE,
  ...multilineTruncate,
  '*': {
    display: 'inline',
  },
}));
export const AuthorsStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
}));
export const SingleAuthorStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.8),
}));
export const AvatarImage = styled('img')(() => ({
  borderRadius: '100%',
  width: '40px',
  height: '40px',
}));

export const StyledAuthorName = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyBackgroundColor?.SURFACE,
  whiteSpace: 'nowrap',
}));
export const StyledContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyContent?.DISABLED,
  ...multilineTruncate,
  '*': {
    display: 'inline',
  },
}));
export const StyledDesktopTechnologiesStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

import { useTheme } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { useRef, useReducer, useCallback, useEffect } from 'react';

import type { BubbleProps } from './Bubble.types';
import type { AnimationPropsType, IValuesOnDrag } from './BubbleReducer';
import {
  reducer,
  initialState,
  doSetValuesOnDrag,
  doSetAnimationProps,
  doSetShouldBeGradient,
  doSetIsDraggingComplete,
} from './BubbleReducer';
import {
  StyledStack,
  NoiseBackground,
  StyledMotionDiv,
  StyledCircleContainer,
} from './styles';

export const Bubble = ({ children, params }: BubbleProps) => {
  const {
    top,
    left,
    boxShadow,
    duration,
    floatingY,
    name,
    icon,
    gradientColor,
    withoutGradient,
  } = params;
  const BubbleIcon = icon;
  const theme = useTheme();
  const size = params.size ? params.size : 100;
  const bubbleRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetIsDraggingComplete = useCallback(
    (value: boolean) => {
      dispatch(doSetIsDraggingComplete(value));
    },
    [dispatch]
  );
  const handleSetShouldBeGradient = useCallback(
    (value: boolean) => {
      dispatch(doSetShouldBeGradient(value));
    },
    [dispatch]
  );
  const handleSetAnimationProps = useCallback(
    (value: AnimationPropsType) => {
      dispatch(doSetAnimationProps(value));
    },
    [dispatch]
  );
  const handleSetValuesOnDrag = useCallback(
    (value: IValuesOnDrag) => {
      dispatch(doSetValuesOnDrag(value));
    },
    [dispatch]
  );
  const onHover = debounce(
    useCallback(
      (e: React.MouseEvent | MouseEvent) => {
        const hoverArea = 0.75;
        if (!bubbleRef?.current) return;
        const container = bubbleRef?.current?.getBoundingClientRect();
        const bubbleCoords = {
          x: container.left + container.width * 0.5,
          y: container.top + container.height * 0.5,
          width: container.width,
        };
        const x = e.clientX - bubbleCoords?.x;
        const y = e.clientY - bubbleCoords?.y;
        const distance = Math.sqrt(x * x + y * y);
        if (distance >= bubbleCoords.width * hoverArea) {
          handleSetAnimationProps({ x: 0, y: 0, scale: 1 });
        } else {
          handleSetAnimationProps({ x: x * 0.4, y: y * 0.4, scale: 1.1 });
        }
      },
      [bubbleRef?.current]
    ),
    4
  );

  const handleAnimationComplete = useCallback(() => {
    if (state.isDraggingComplete) {
      handleSetValuesOnDrag({
        isDraggingComplete: false,
        shouldTriggerFloating: true,
      });
    }
  }, [state.isDraggingComplete]);

  const handleHoverEnd = useCallback(() => {
    handleSetIsDraggingComplete(true);
  }, []);

  const handleGradientHoverEnd = useCallback(() => {
    handleSetShouldBeGradient(false);
  }, []);
  const handleGradientHoverStart = useCallback(() => {
    handleSetShouldBeGradient(true);
  }, []);

  const addFloatingClassName = useCallback(() => {
    if (state.shouldTriggerFloating && !theme.utils.isMobile())
      return 'FloatingItem';
    else return '';
  }, [state.shouldTriggerFloating]);

  return (
    <StyledCircleContainer
      className={`AnimateElement ${addFloatingClassName()}`}
      ref={bubbleRef}
      onMouseMove={(e) => onHover(e)}
      onHoverStart={(e) => {
        handleSetValuesOnDrag({
          isDraggingComplete: false,
          shouldTriggerFloating: false,
        });

        onHover(e);
      }}
      onAnimationComplete={handleAnimationComplete}
      whileHover={{
        y: `${state.animationProps.y}px`,
        x: `${state.animationProps.x}px`,
        scale: state.animationProps.scale,
      }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 10,
      }}
      onHoverEnd={handleHoverEnd}
      sx={{
        top: theme.utils.fluidSize({
          minSize: top * (1.5 / 2),
          maxSize: top,
        }),
        left: theme.utils.fluidSize({
          minSize: left * (0.2 / 2),
          maxSize: left,
        }),
        [`@keyframes floating${name}`]: {
          '0%': {
            transform: 'translateY(0px)',
          },
          '50%': {
            transform: `translateY(${floatingY}px)`,
          },
          '100%': {
            transform: 'translateY(0px)',
          },
        },

        animation: '0',
        '&.FloatingItem': {
          animation: `floating${name} ${duration}s ease-in-out infinite`,
        },

        width: `${size}%`,
        minWidth: theme.utils.fluidSize({
          minSize: size * (6.5 / 10),
          maxSize: size,
        }),
        [theme.breakpoints.up('xl')]: {
          top: top,
          left: left,
          minWidth: 'auto',
        },
        [theme.breakpoints.down('md')]: {
          top: top,
          left: left,
          minWidth: 'auto',
        },
      }}>
      <StyledMotionDiv
        className="Bubble"
        boxshadow={boxShadow}
        onHoverStart={handleGradientHoverStart}
        onHoverEnd={handleGradientHoverEnd}
        transition={{ duration: 0.4 }}>
        <NoiseBackground />
        {BubbleIcon && (
          <BubbleIcon
            sx={{ width: '100%', height: '100%' }}
            shouldbegradient={
              !withoutGradient && state.shouldBeGradient ? true : false
            }
            iconType="thin"
            gradientColor={gradientColor}
          />
        )}
      </StyledMotionDiv>
    </StyledCircleContainer>
  );
};

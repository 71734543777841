import React, { memo, useEffect, useState } from 'react';

import horizontalBannerImage from '../../../assets/png/Rumblefish23Theme/aiBanner/horizontalAIBannerImage.png';
import verticalBannerImage from '../../../assets/png/Rumblefish23Theme/aiBanner/verticalAIBannerImage.png';
import type {
  WidgetClassesType,
  SlidingWidgetWrapperProps,
} from './SlidingWidgetWrapper.types';
import {
  StyledLgButton,
  StyledMotionDiv,
  StyledBannerText,
  StyledLinkButton,
  StyledBannerImage,
  StyledMainContainer,
  StyledContentSection,
  StyledAbsoluteContainer,
} from './styles';

const WidgetContent = memo(() => {
  return (
    <StyledMainContainer>
      <StyledBannerImage
        sx={{ display: { xs: 'none', md: 'block' } }}
        src={verticalBannerImage}
      />
      <StyledBannerImage
        sx={{ display: { md: 'none' } }}
        src={horizontalBannerImage}
      />
      <StyledContentSection>
        <StyledBannerText variant="strong_500">
          AI Chatbot for the legal industry
        </StyledBannerText>

        <StyledLinkButton variant="link" link="/case-studies/ai-chat-assistant">
          Read the case study
        </StyledLinkButton>
        <StyledLgButton link="/case-studies/ai-chat-assistant" variant="link">
          Let's read
        </StyledLgButton>
      </StyledContentSection>
    </StyledMainContainer>
  );
});
WidgetContent.displayName = 'WidgetContent';

export const SlidingWidgetWrapper = ({
  anchorRef,
  children,
}: SlidingWidgetWrapperProps) => {
  const [widgetClassName, setWidgetClassName] =
    useState<WidgetClassesType>('NotVisible');

  useEffect(() => {
    const currentAnchor = anchorRef.current;
    if (!currentAnchor) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setWidgetClassName('Visible');
        } else {
          setWidgetClassName('NotVisible');
        }
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(currentAnchor);

    return () => observer.unobserve(currentAnchor);
  }, [anchorRef]);

  return (
    <>
      <StyledAbsoluteContainer className={widgetClassName}>
        <StyledMotionDiv className={widgetClassName}>
          <WidgetContent />
        </StyledMotionDiv>
      </StyledAbsoluteContainer>
      {children}
    </>
  );
};

export interface ITestimonialsReducerState {
  screenWidth: number;
  parentPadding: number;
  currentSelectedSlide: number;
}

export const initialState = {
  screenWidth: 0,
  parentPadding: 0,
  currentSelectedSlide: 0,
};
interface IPayloadSetDimensionsValues {
  screenWidth: number;
  parentPadding: number;
}
type IActionSetDimensionsValues = {
  type: 'setDimensionsValues';
  payload: IPayloadSetDimensionsValues;
};

type IActionSetCurrentSelectedSlide = {
  type: 'setCurrentSelectedSlide';
  payload: number;
};
export type IAction =
  | IActionSetDimensionsValues
  | IActionSetCurrentSelectedSlide;

export function reducer(
  state: ITestimonialsReducerState,
  action: IAction
): ITestimonialsReducerState {
  switch (action.type) {
    case 'setDimensionsValues': {
      return {
        ...state,
        parentPadding: action.payload.parentPadding,
        screenWidth: action.payload.screenWidth,
      };
    }
    case 'setCurrentSelectedSlide': {
      return {
        ...state,
        currentSelectedSlide: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
}

import type { FormControlLabelProps } from '@mui/material';
import type { ChangeEvent } from 'react';
import type React from 'react';

export interface CheckboxProps extends Partial<FormControlLabelProps> {
  label: string | React.ReactElement;
  value: boolean;
  checkboxId: string;
  onChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  color?: string;
}

const PREFIX = 'Checkbox';
export const CheckboxClasses = {
  error: `${PREFIX}-error`,
  navy: `${PREFIX}-navyMode`,
  light: `${PREFIX}-lightMode`,
  dark: `${PREFIX}-darkMode`,
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Stack } from '@mui/material';
import parse from 'html-react-parser';
import React from 'react';

import { Button, removeParagraphTag } from '../../../../Rumblefish23Theme';
import type { CustomSvgIconsProps } from '../Icons/CustomSvgIcons.types';
import type { CustomizedAccordionProps } from './CustomizedAccordion.types';
import {
  StyledTitle,
  StyledDescription,
  StyledMainWrapper,
  StyledMuiAccordion,
  StyledMuiAccordionDetails,
  StyledMuiAccordionSummary,
} from './styles';

export const CustomizedAccordion = ({
  data,
  CustomButton,
}: CustomizedAccordionProps): ReactJSXElement => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <>
      {(data || []).map((Panel, index) => {
        const shouldBeGradient =
          !!Panel.gradientOnFocus && expanded === `panel${index}`;
        const transitionDuration = 0.4;

        const iconProps: CustomSvgIconsProps = {
          shouldbegradient: shouldBeGradient,
          transitionduration: transitionDuration,
          prefix: 'accordion',
          sx: {
            width: '40px',
            height: '40px',
          },
        };
        return (
          <StyledMainWrapper key={index}>
            <StyledMuiAccordion
              disableGutters
              elevation={0}
              square
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}>
              <StyledMuiAccordionSummary
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
                expandIcon={
                  <Stack>
                    <AddRoundedIcon sx={{ fontSize: '28px' }} />
                  </Stack>
                }>
                {Panel.icon && <Panel.icon {...iconProps} />}

                <StyledTitle
                  variant="strong_300"
                  data-text={Panel.title}
                  transitionduration={transitionDuration}
                  shouldbegradient={shouldBeGradient ? 1 : 0}
                  gradient={Panel.gradientOnFocus}>
                  {Panel.title}
                </StyledTitle>
              </StyledMuiAccordionSummary>
              <StyledMuiAccordionDetails>
                <StyledDescription variant="regular_300">
                  {Panel.customDescription
                    ? Panel.customDescription()
                    : parse(removeParagraphTag(Panel.description))}
                </StyledDescription>
                {Panel.linkTo && (
                  <Button link={Panel.linkTo} variant="link">
                    See more
                  </Button>
                )}
                {CustomButton}
              </StyledMuiAccordionDetails>
            </StyledMuiAccordion>
          </StyledMainWrapper>
        );
      })}
    </>
  );
};

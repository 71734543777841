import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from 'react';
import { StyledTestimonialsStack } from './styles';
import Slider, { LazyLoadTypes } from 'react-slick';
import { useTheme } from '@mui/material';
import { initialState, reducer } from './TestimonialsSliderReducer';
import { Testimonial } from '../../../../Rumblefish23Theme';

import {
  ISliderNavigationRef,
  TestimonialsSliderProps,
} from './TestimonialsSlider.types';
export const TestimonialsSlider = forwardRef<
  ISliderNavigationRef,
  TestimonialsSliderProps
>(({ sectionRef, testimonialsData }, ref): ReactJSXElement => {
  const theme = useTheme();
  const sliderRef = useRef<Slider>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const testimonialsBreakpoints = {
    large: 2000,
    medium: 1280,
    small: 800,
  };
  useImperativeHandle(ref, () => ({
    changeTestimonial(prevOrNext) {
      if (!sliderRef.current) return;
      if (prevOrNext === 'prev') {
        sliderRef.current.slickPrev();
      } else if (prevOrNext === 'next') {
        sliderRef.current.slickNext();
      }
    },
  }));
  const doSetDimensionsStateValues = () => {
    dispatch({
      type: 'setDimensionsValues',
      payload: {
        parentPadding: getPadding(),
        screenWidth: window.innerWidth,
      },
    });
  };
  const getPadding = (): number => {
    if (!sectionRef.current) return 0;

    if (window.innerWidth > testimonialsBreakpoints.large)
      return (testimonialsBreakpoints.large - theme.breakpoints.values.xl) / 2;
    return (
      (window.innerWidth - sectionRef.current.getBoundingClientRect().width) / 2
    );
  };
  const calcShift = (
    minSize: number,
    maxSize: number,
    breakPoint: number
  ): number => {
    if (!sectionRef.current) return 0;
    const shift =
      minSize +
      (maxSize - minSize) *
        ((window.innerWidth - breakPoint) /
          (testimonialsBreakpoints.large - breakPoint));
    if (shift < minSize) return minSize;
    else if (shift > maxSize) return maxSize;
    else return shift;
  };

  const testimonialStyle: CSSProperties = {
    transform: `translateX(-${calcShift(
      -50,
      160,
      testimonialsBreakpoints.medium
    )}px)`,
    [theme.breakpoints.down(testimonialsBreakpoints.medium)]: {
      transform: `none`,
    },
  };

  const settings = {
    arrows: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    beforeChange: (_: number, nextSlide: number) => {
      dispatch({
        type: 'setCurrentSelectedSlide',
        payload: nextSlide,
      });
    },
    centerPadding: `${
      state.parentPadding + calcShift(-50, 160, testimonialsBreakpoints.medium)
    }px`,

    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 8000,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          centerPadding: `${calcShift(
            20,
            550,
            testimonialsBreakpoints.small
          )}px`,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          centerMode: false,
        },
      },
    ],
  };
  useEffect(() => {
    if (sectionRef.current) {
      doSetDimensionsStateValues();
    }
  }, [sectionRef]);

  useEffect(() => {
    window.addEventListener('resize', doSetDimensionsStateValues);
    return () => {
      window.removeEventListener('resize', doSetDimensionsStateValues);
    };
  }, []);
  return (
    <StyledTestimonialsStack
      parentpadding={state.parentPadding}
      ref={sectionRef}>
      <Slider ref={sliderRef} {...settings}>
        {testimonialsData.map(({ attributes }, index) => {
          return (
            <Testimonial
              key={index}
              customStyles={{
                ...testimonialStyle,
                transition: 'opacity 0.15s',
                opacity:
                  index !== state.currentSelectedSlide &&
                  state.screenWidth > testimonialsBreakpoints.medium
                    ? 0.4
                    : 1,
              }}
              name={attributes?.author?.data?.attributes?.Name || ''}
              positionInCompany={
                attributes?.author?.data?.attributes?.Position || ''
              }
              text={attributes?.body || ''}
              avatarSrc={
                attributes?.author?.data?.attributes?.Avatar?.data?.attributes
                  ?.url || ''
              }
            />
          );
        })}
      </Slider>
    </StyledTestimonialsStack>
  );
});

import React from 'react';
import type { Ipage } from './NavigatorContent.types';
import { StyledText } from './styles';
import { Link } from '../Link';
export const NavigatorContent = ({ page }: { page: Ipage }) => {
  return (
    <>
      <Link to={page.link}>
        <StyledText variant="strong_400">{page.name}</StyledText>
      </Link>
      <StyledText variant="regular_400">/</StyledText>
    </>
  );
};

import { Stack, styled, Typography } from '@mui/material';
import ReCaptcha from 'react-google-recaptcha';
import { Button } from '../Button';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flex: 0.7,
  [theme.breakpoints.down('xl')]: {
    flex: 1,
  },
}));
export const StyledRecaptcha = styled(ReCaptcha)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
export const StyledLoadingIcon = styled(`img`)(() => ({
  animation: 'spin 4s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(deg0)',
    },
  },
}));

export const StyledButtonWrapper = styled(Stack)(({ theme }) => ({
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: theme.spacing(4),
}));

export const StyledButton = styled(Button)<{ color?: string }>(
  ({ theme, color }) => ({
    minWidth: '208px',
    minHeight: theme.spacing(6),
    span: {
      justifyContent: 'center',
      minHeight: '28px',
    },

    '.MuiStack-root': color
      ? {
          backgroundColor: 'transparent !important',
          border: '1px solid white',
          boxShadow: `0px 0px 6px 4px ${color}, 0px 0px 6px 4px ${color} inset !important`,
        }
      : {},
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  })
);
export const CheckboxLabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  height: '100%',

  ['a']: {
    textDecoration: 'underline',
  },
}));

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IImageDimensions, ZoomingImageProps } from './ZoomingImage.types';
import { MainContainer, StyledWrapperPlx, StyledImagePlx } from './styles';
import { PlxItem } from 'react-plx';
import { getOffsetTop } from '../utils/getOffsetTop';
export const ZoomingImage = ({
  imgUrl,
  fromRightSideOn,
  ...props
}: ZoomingImageProps) => {
  const [animationParams, setAnimationParams] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  const [imageDimensions, setImageDimensions] = useState<IImageDimensions>({
    height: 0,
    width: 0,
  });
  const [shouldDisabledAnim, setShouldDisabledAnim] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const getEndPosition = () => {
    if (!ref.current) return 0;
    const imgContainerHeight =
      ref?.current?.getBoundingClientRect().height || 0;

    return (
      imgContainerHeight + (window.innerHeight - imgContainerHeight) * (1 / 2)
    );
  };

  useEffect(() => {
    const img = new Image();
    img.src = imgUrl;

    img.onload = () => {
      const { height, width } = img;
      setImageDimensions({
        height,
        width,
      });
    };
  }, []);
  const animationParamsInit = () => {
    if (!ref.current) return;
    const offsetTop = getOffsetTop(ref.current) - window.innerHeight;
    setAnimationParams({ start: offsetTop, end: getEndPosition() });
  };
  const handlePlxEnd = useCallback(() => {
    if (animationParams.start < window.scrollY) setShouldDisabledAnim(true);
  }, [animationParams.start]);

  useEffect(() => {
    animationParamsInit();
  }, [imageDimensions]);

  useEffect(() => {
    window.addEventListener('resize', animationParamsInit, false);
  }, []);

  const parallaxWrapperData: PlxItem[] = [
    {
      start: animationParams.start,
      duration: animationParams.end,
      easing: 'easeIn',
      properties: [
        {
          startValue: fromRightSideOn
            ? imageDimensions.width
            : -imageDimensions.width,
          endValue: 0,
          property: 'translateX',
        },
        {
          startValue: -imageDimensions.height,
          endValue: 0,
          property: 'translateY',
        },
      ],
    },
  ];
  const parallaxImageData: PlxItem[] = [
    {
      start: animationParams.start,
      duration: animationParams.end,
      easing: 'easeIn',
      properties: [
        {
          startValue: fromRightSideOn
            ? -imageDimensions.width
            : imageDimensions.width,
          endValue: 0,
          property: 'translateX',
        },
        {
          startValue: imageDimensions.height,
          endValue: 0,
          property: 'translateY',
        },
      ],
    },
  ];
  return (
    <MainContainer
      {...props}
      ref={ref}
      sx={{
        aspectRatio: `${imageDimensions.width / imageDimensions.height} / 1`,
      }}>
      <StyledWrapperPlx
        disabled={shouldDisabledAnim}
        parallaxData={parallaxWrapperData}
        onPlxEnd={handlePlxEnd}>
        <StyledImagePlx
          disabled={shouldDisabledAnim}
          parallaxData={parallaxImageData}
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      </StyledWrapperPlx>
    </MainContainer>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

import type { SectionNameBannerProps } from './NameBanner.types';
import { StyledText } from './styles';

const PREFIX = 'NameBanner';

export const classes = {
  section: `${PREFIX}-section`,
  element: `${PREFIX}-element`,
};

export const NameBanner = ({
  type,
  text,
  color = 'raspberry',
  ...props
}: SectionNameBannerProps): ReactJSXElement => {
  return (
    <StyledText
      className={classes[type]}
      variant={type === 'section' ? 'regular_200' : 'strong_100'}
      color={color}
      {...props}>
      {text}
    </StyledText>
  );
};

export class PausableSleep {
  ms: number;
  startMs: number;
  timeLeft: number;
  promise: Promise<void>;
  timeout: ReturnType<typeof setTimeout>;
  resolve!: () => void;

  constructor(ms: number) {
    this.ms = ms;
    this.startMs = Date.now();
    this.timeLeft = ms;
    this.promise = new Promise((resolve) => (this.resolve = resolve));
    this.timeout = setTimeout(() => this.resolve(), ms);
  }

  stop() {
    clearTimeout(this.timeout);
    this.timeLeft = this.timeLeft - (Date.now() - this.startMs);
  }

  resume() {
    this.startMs = Date.now();
    this.timeout = setTimeout(() => this.resolve(), this.timeLeft);
  }
}

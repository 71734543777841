import React, { memo } from 'react';
import { useIsWebkit } from '../../../../hooks/useIsWebkit';
import { ICard } from './Card.types';
import {
  BubbleWrapper,
  Description,
  HeadlineWrapper,
  MainCardWrapper,
  Title,
} from './styles';
import { Bubble } from '../Bubble';
export const Card = memo(
  ({ icon, title, description, distance, duration }: ICard) => {
    const isWebkit = useIsWebkit();
    const webkitClassName = isWebkit ? 'UsingWebkit' : '';

    return (
      <MainCardWrapper className={webkitClassName}>
        <HeadlineWrapper>
          <BubbleWrapper>
            <Bubble
              params={{
                withoutGradient: true,
                name: `card_${title.replace(/[^A-Z0-9]/gi, '_')}`,
                icon: icon,
                top: 0,
                left: 0,
                size: 100,
                duration: duration,
                floatingY: distance,
              }}
            />
          </BubbleWrapper>

          <Title variant="strong_400">{title}</Title>
        </HeadlineWrapper>
        <Description variant="regular_300">{description}</Description>
      </MainCardWrapper>
    );
  }
);

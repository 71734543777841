import { Box, Stack, styled } from '@mui/material';

export const StyledLineWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '1px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    marginRight: theme.spacing(4),
  },
}));

export const StyledDottedLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  borderLeft: `2.5px dotted ${theme.palette.invertedContent?.PRIMARY}`,
  opacity: 0.15,
  index: 0,
}));

import { Stack, Typography, styled } from '@mui/material';

export const StyledStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: '100%',
}));

export const StyledDesktopImageStack = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  height: '100%',
  aspectRatio: '1',
  borderRadius: '50%',
  contain: 'paint',
  zIndex: 2,
  img: {
    width: '100%',
    height: '100%',
  },
  [theme.breakpoints.down(1280)]: {
    display: 'none',
  },
}));

export const StyledContentStack = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  borderRadius: '360px',
  minWidth: '872px',
  maxWidth: '872px',
  transform: 'translateX(-100px)',
  padding: theme.spacing(4, 16, 4, 32),
  [theme.breakpoints.down(1280)]: {
    padding: theme.spacing(4, 11, 4, 23),
    minWidth: '750px',
    maxWidth: '750px',
    transform: 'none',
  },

  [theme.breakpoints.down(800)]: {
    minWidth: 'auto',
    maxWidth: 'auto',
    padding: theme.spacing(0, 6),
    transform: 'none',
    border: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    maxWidth: 'auto',
    padding: theme.spacing(0, 3),
    transform: 'none',
    border: 'none',
  },
}));

export const StyledTextStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

export const StyledQuote = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  position: 'absolute',
  transform: 'translateX(-120%)',
  width: 'min-content',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  clipPath: 'inset(1px)',
  [theme.breakpoints.down(800)]: {
    display: 'none',
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));

export const StyledCapionStack = styled(Stack)(({ theme }) => ({
  width: 'min-content',
  whiteSpace: 'nowrap',
}));

export const StyledFooterStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  // alignItems: 'center',
  flexDirection: 'row',
  height: '90px !important',
}));

export const StyledPosition = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
}));

export const StyledMobileImageStack = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  contain: 'paint',
  borderRadius: '50%',
  img: {
    width: '100%',
    height: '100%',
  },
  minWidth: '90px',
  width: '90px',
  height: '90px !important',
  marginRight: '0px',
  marginLeft: 'auto',
  [theme.breakpoints.up(1280)]: {
    display: 'none !important',
  },
  [theme.breakpoints.down(800)]: {
    minWidth: '88px',
    height: '88px',
  },
}));

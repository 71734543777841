import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export const useIsWebkit = () => {
  const theme = useTheme();
  const [isWebkit, setIsWebkit] = useState<boolean>(false);
  useEffect(() => {
    setIsWebkit(theme.utils.isWebkit());
  }, []);

  return isWebkit;
};

import { Checkbox, FormControlLabel, styled } from '@mui/material';

import { CheckboxClasses } from './types';
export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ theme, color }) => ({
  [`&.${CheckboxClasses.error}`]: {
    '& span': {
      color: `${theme.palette.redColors?.PLUS_1} !important`,
    },
  },

  marginTop: theme.spacing(2),
  marginLeft: 0,
  alignItems: `center`,
  cursor: `default`,
  '& .Mui-checked': {
    '& span': {
      border: color
        ? `1px solid ${color} !important`
        : `1px solid ${theme.palette.blueColors?._0} !important`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: `flex-start`,
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 0),
  cursor: `default`,
}));

type StyledCheckboxIconProps = {
  disabled: boolean;
  color?: string;
};

export const StyledCheckboxIcon = styled(`span`, {
  shouldForwardProp: (prop) => prop !== 'color',
})<StyledCheckboxIconProps>(({ theme, color, disabled }) => ({
  borderRadius: `4px`,
  width: `16px`,
  height: `16px`,
  alignSelf: `flex-start`,
  cursor: `default !important`,

  'input ~ &': {
    border: `1px solid ${theme.palette.greyOutlineAction?.ENABLED}`,
  },
  'input:hover ~ &': {
    borderColor: disabled
      ? theme.palette.greyOutlineAction?.ENABLED
      : color
      ? color
      : theme.palette.blueColors?._0,
  },
}));

export const StyledCheckboxIconChecked = styled(StyledCheckboxIcon, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ theme, color }) => ({
  width: '16px',
  height: '16px',
  background: color ? color : theme.palette.blueColors?._0,
}));

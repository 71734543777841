import { Stack, styled } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
export const StyledWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '330px',
  height: '450px',
  background: theme.palette.invertedColors?.PLUS_8,
  padding: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5, 1.5),
  },
  borderRadius: theme.spacing(3),
  gap: theme.spacing(2),
}));

export const StyledTransitionGroup = styled(TransitionGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

export const PulseLoaderWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
}));
export const FingerPrintWrapper = styled(Stack)(({ theme }) => ({
  width: `calc(100% - 2 * ${theme.spacing(2.5)})`,
  position: 'absolute',
  bottom: theme.spacing(2.5),
  alignItems: 'center',
  opacity: 0,
  transition: `opacity 1s ease-in-out`,
  ['&.fingerPrint-entering']: {
    opacity: 1,
  },
  ['&.fingerPrint-entered']: {
    opacity: 1,
  },
  ['&.fingerPrint-exiting']: {
    opacity: 0,
  },
  ['&.fingerPrint-exited']: {
    opacity: 0,
  },
  svg: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.invertedColors?.PLUS_5,
  },
}));

import { Stack, Typography, styled } from '@mui/material';
import { motion } from 'framer-motion';
export const StyledText = styled(Typography)(({ theme }) => ({
  gap: theme.spacing(1),
  position: 'relative',

  flexWrap: 'wrap',
  fontWeight: '900',
  lineHeight: `${theme.utils.fluidSize({
    minSize: 46,
    maxSize: 56,
  })} !important`,

  fontSize: `${theme.utils.fluidSize({ minSize: 42, maxSize: 48 })} !important`,

  width: '65%',
  [theme.breakpoints.down('xl')]: {
    width: '70%',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '48px !important',
    lineHeight: '56px !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: '85%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '32px !important',
    lineHeight: '36px !important',
    width: '100%',
  },
  span: {
    [theme.breakpoints.up('xl')]: {
      lineHeight: '47px !important',
    },

    [theme.breakpoints.down(1500)]: {
      width: '75%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '85%',
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: '36px !important',
      width: '100%',
    },
  },
}));
export const StyledAnimatedSpan = styled(motion.span)(({ theme }) => ({
  background: `linear-gradient(${theme.palette.invertedContent?.PRIMARY},${theme.palette.invertedContent?.PRIMARY})left no-repeat ${theme.palette.invertedBackgroundColor?.ON_SUBTLE}`,
  willChange: 'unset !important',
  transition: 'background-size 0.025s linear',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  color: 'transparent',
  [theme.breakpoints.up('sm')]: {
    '&.UsingWebkit': {
      all: 'unset',
    },
  },
}));

export const SectionNameBannerStack = styled(Stack)(({ theme }) => ({
  width: '85%',

  [theme.breakpoints.up('lg')]: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
export const HeighestStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: '300vh',

  [theme.breakpoints.down('md')]: {
    height: 'auto',
    margin: theme.spacing(8, 0),
  },
  [theme.breakpoints.up('sm')]: {
    '&.UsingWebkit': {
      height: 'auto',
      margin: theme.spacing(17, 0),
    },
  },
}));
export const StickyStack = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: '-3.7%',
  left: 0,
  padding: '25% 0',
  display: 'flex',
  alignItems: 'center',
  height: '37%',
  justifyContent: 'center',

  [theme.breakpoints.down(1500)]: {
    padding: '15% 0',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '20% 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 0',
    height: 'auto',
    position: 'static',
  },
  [theme.breakpoints.up('sm')]: {
    '&.UsingWebkit': {
      padding: '0 0',
      height: 'auto',
      position: 'static',

      p: {
        color: theme.palette.invertedContent?.PRIMARY,
      },
    },
  },
}));

export const TextStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
  },
}));
export const GlowStack = styled(Stack)(({ theme }) => ({
  width: '90%',
  height: 'calc(90vw / 2)',
  borderRadius: '0 0 calc(90vw / 2) calc(90vw / 2)',
  position: 'absolute',
  top: '-3.7%',
  left: 0,
  zIndex: -10,
  background: theme.palette.invertedColors?.PLUS_8,
  willChange: 'transform',
  [theme.breakpoints.up('sm')]: {
    '&.UsingWebkit': {
      top: -136,
    },
  },
  filter: 'blur(200px)',
}));
export const AbsoluteGlowWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

import { styled, Typography } from '@mui/material';

import { classes } from './NameBanner';

export const StyledText = styled(Typography)<{ color: string }>(
  ({ theme, color }) => ({
    width: 'min-content',
    whiteSpace: 'nowrap',
    [`&.${classes.element}`]: {
      color: theme.palette.greyBackgroundColor?.SURFACE,
      padding: theme.spacing(0.625, 1.5, 0.875, 1.5),
      borderRadius: theme.spacing(4),
      border: `1px solid ${theme.palette.invertedSubtleAction?.ENABLED}`,
      cursor: 'auto pointer',
    },
    [`&.${classes.section}`]: {
      cursor: 'auto !important',
      color: theme.palette.invertedContent?.PRIMARY,
      fontWeight: 700,
      position: 'relative',
      padding: theme.spacing(1.2, 2),

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        borderRadius: '40px',
        border: 'solid transparent',
        borderWidth: theme.spacing(0.25),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        ...theme.mixins?.[`${color}Gradient`],
        backgroundOrigin: 'border-box',
        WebkitMask:
          'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'destination-out',
        maskComposite: 'exclude',
      },
    },
  })
);

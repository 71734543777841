import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { useRef } from 'react';

import cycLogo from '../../../assets/svg/logos/cycLogo.svg';
import makerDaoLogo from '../../../assets/svg/logos/makerDaoLogo.svg';
import spaceInchLogo from '../../../assets/svg/logos/spaceInchLogo.svg';
import verifiIdLogo from '../../../assets/svg/logos/verifiIdLogo.svg';
import zbayLogo from '../../../assets/svg/logos/zbayLogo.svg';

import type { CompaniesCarouselProps } from './CompaniesCarousel.types';
import {
  SliderStack,
  SliderTrack,
  StyledImage,
  SlideElementStack,
} from './styles';

const defaultCompanies = [
  spaceInchLogo,
  zbayLogo,
  verifiIdLogo,
  cycLogo,
  makerDaoLogo,
];

export const CompaniesCarousel = ({
  companiesLogos,
  shouldInvert = true,
}: CompaniesCarouselProps): ReactJSXElement => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const companiesData = companiesLogos || defaultCompanies;
  const companiesQuantity: number = companiesData.length;

  return (
    <SliderStack ref={carouselRef}>
      <SliderTrack
        className="AnimateElement"
        companiesquantity={companiesQuantity}>
        {[...Array(2)].map(() => {
          return companiesData.map((icon, i) => {
            return (
              <SlideElementStack key={i} companiesquantity={companiesQuantity}>
                <StyledImage
                  alt={`companiesCarouselLogo${i}`}
                  draggable={false}
                  key={`ImageKey: ${i}`}
                  src={icon}
                  shouldInvert={shouldInvert}
                />
              </SlideElementStack>
            );
          });
        })}
      </SliderTrack>
    </SliderStack>
  );
};

import { Stack, Typography, styled } from '@mui/material';
import { CSSProperties } from 'react';
const minDimension = '120px';
const maxDimension = '236px';
const dimensions: CSSProperties = {
  minWidth: minDimension,
  minHeight: minDimension,
  maxWidth: maxDimension,
  maxHeight: maxDimension,
  borderRadius: '360px',
};
export const StyledImageWrapper = styled(Stack)(({ theme }) => ({
  width: theme.utils.fluidSize({ minSize: 120, maxSize: 236 }),
  height: theme.utils.fluidSize({ minSize: 120, maxSize: 236 }),
  overflow: 'hidden',
  position: 'relative',
  ...dimensions,
  '&::after': {
    content: "''",
    position: 'absolute',
    width: theme.utils.fluidSize({ minSize: 120, maxSize: 236 }),
    height: theme.utils.fluidSize({ minSize: 120, maxSize: 236 }),
    ...theme.mixins.purpleGradientTransparent,
    background: 'none',
    zIndex: 2,
    ...dimensions,
  },
}));

export const MainWrapper = styled('div')(() => ({
  display: 'inline-flex',
}));
export const ContentWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4, 8, 4, 12),
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  height: theme.utils.fluidSize({ minSize: 120, maxSize: 236 }),
  width: theme.utils.fluidSize({ minSize: 240, maxSize: 366 }),
  marginLeft: '-50px',
  borderRadius: dimensions?.borderRadius,
  minHeight: dimensions?.minHeight,
  maxHeight: dimensions?.maxHeight,
  minWidth: `calc(${dimensions?.minWidth} * 2 )`,
  maxWidth: `calc(${dimensions?.maxWidth} * 1.55 )`,

  [theme.breakpoints.down('sm')]: {
    flex: 1,
  },
}));
export const TextWrapper = styled(Stack)(() => ({}));
export const StyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const StyledPosition = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.TETRIARY,
}));
export const SocialsWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  color: theme.palette.invertedContent?.TETRIARY,
  gap: theme.spacing(1.5),
}));

import { Stack, Typography, styled } from '@mui/material';
import { Section } from '../Section';

export const ContactSection = styled(Section)(() => ({
  position: 'relative',
}));

export const StyledWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(15, 0),
  gap: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(9, 0),
  },
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
}));

export const InfoStack = styled(Stack)(({ theme }) => ({
  maxWidth: '55%',
  gap: theme.spacing(6),
  [theme.breakpoints.down('xl')]: {
    flex: 1,
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    gap: theme.spacing(2),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  paddingBottom: theme.spacing(0.5),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
}));

export const SubtitleLink = styled(Typography)<{ color?: string }>(
  ({ theme, color }) => ({
    color: color ? color : theme.palette.invertedContent?.SECONDARY,
    textDecoration: 'underline',
  })
);

import { Counter } from '@rumblefish/ui/Rumblefish23Theme';
import { StyledNumbersStack } from './styles';

import React from 'react';
import type { NumberStackProps } from './NumberStack.types';

export const NumberStack = ({ counters }: NumberStackProps) => {
  return (
    <StyledNumbersStack>
      {counters.map((counter, index) => (
        <Counter
          key={index}
          to={counter.number}
          caption={counter.caption}
          infinite={counter.infinite}
        />
      ))}
    </StyledNumbersStack>
  );
};

export default NumberStack;

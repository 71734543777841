import { FormControl, Stack, useTheme } from '@mui/material';
import React from 'react';

import {
  StyledInput,
  StyledLabel,
  StyledError,
  StyledFormControl,
} from './styles';
import type { InputFieldProps } from './types';
import { inputFieldClasses } from './types';

export const InputField = ({
  label,
  errorMessage,
  value,
  onChangeEvent,
  disabled,
  rows = 1,
  variant = 'outlined',
  inputId,
}: InputFieldProps) => {
  const palleteType = useTheme().palette.type;
  return (
    <StyledFormControl>
      <StyledLabel
        required
        shrink
        htmlFor={inputId}
        disabled={disabled}
        className={`${inputFieldClasses[variant]} ${inputFieldClasses[palleteType]}`}>
        {label}
      </StyledLabel>

      <StyledInput
        placeholder={palleteType === 'navy' ? label : ''}
        onChange={onChangeEvent}
        value={value}
        error={errorMessage ? true : false}
        id={inputId}
        inputProps={{ role: 'textbox', 'aria-label': `${inputId}` }}
        disabled={disabled}
        multiline={rows > 1}
        rows={rows}
        className={`${inputFieldClasses[variant]} ${inputFieldClasses[palleteType]}`}
      />
      {errorMessage ? (
        <StyledError
          shrink
          htmlFor={inputId}
          role="status"
          aria-label={`${inputId}-error`}>
          {errorMessage}
        </StyledError>
      ) : null}
    </StyledFormControl>
  );
};

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { animate, useInView } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { CounterProps } from './Counter.types';
import { StyledCaption, StyledNumber, StyledStack } from './styles';

export const Counter = ({
  to,
  caption,
  infinite,
}: CounterProps): ReactJSXElement => {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const inViewRef = useRef(null);
  const inView = useInView(inViewRef, { once: true });
  const numberRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (inView) {
      if (infinite) {
        const controls = animate(0, to, {
          duration: 2,
          ease: 'easeOut',
          delay: 0.1,
          onUpdate: (value) => {
            if (numberRef.current) {
              numberRef.current.innerHTML = value.toFixed(0);
            }
          },
          onPlay: () => {
            setAnimationCompleted(false);
          },
          onComplete: () => {
            setAnimationCompleted(true);
            if (numberRef.current) {
              numberRef.current.innerHTML = '∞';
            }
          },
        });

        return () => {
          controls.stop();
        };
      } else {
        const controls = animate(0, to, {
          duration: 2,
          ease: 'easeOut',
          delay: 0.1,
          onUpdate: (value) => {
            if (numberRef.current)
              numberRef.current.innerHTML = value.toFixed(0);
          },
        });
        return () => controls.stop();
      }
    }
  }, [to, inView, infinite]);

  return (
    <StyledStack ref={inViewRef}>
      <StyledNumber
        sx={{
          ...(animationCompleted && infinite
            ? {
                transition: 'font-size 0.5s ease-in-out',
                fontSize: '160px !important',
                marginTop: '-10px !important',
                lineHeight: '98px !important',
              }
            : {}),
        }}
        variant={'display_md'}
        ref={numberRef}>
        0
      </StyledNumber>
      {caption && <StyledCaption variant="strong_300">{caption}</StyledCaption>}
    </StyledStack>
  );
};

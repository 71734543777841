import { Stack, Typography, styled } from '@mui/material';

export const RowWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  gap: theme.spacing(0.5),
  ['&.item-enter']: {
    opacity: 0,
  },
  ['&.item-enter-active']: {
    opacity: 1,
    transition: 'opacity 300ms ease-in',
  },
  ['&.item-exit']: {
    opacity: 1,
  },
  ['&.item-exit-active']: {
    opacity: 0,
    transition: 'opacity 300ms ease-in',
  },
}));
export const MessageContentWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  alignItems: 'flex-start',
  width: '80%',
  [theme.breakpoints.down('sm')]: {
    width: '85%',
  },
}));

export const StyledAvatar = styled('img')(({ theme }) => ({
  width: '30px',
  height: '30px',
}));
export const StyledAiAvatar = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(7),
  minWidth: '35px',
  height: '35px',
  background: 'linear-gradient(275deg, #DE5EB3 0%, #6792F4 100%)',
  color: 'white',
}));
export const TextWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  background: theme.palette.blueColors?._0,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
}));

export const StyledMessageText = styled(Typography)(({ theme }) => ({
  lineHeight: '16px',
  fontWeight: 450,
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const StyledTimeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
  padding: theme.spacing(0, 1.5),
}));
export const StyledImage = styled('img')(({ theme }) => ({
  width: theme.spacing(7.5),
  height: theme.spacing(7.5),
}));
export const ImageSectionWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));
export const StyledImageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.TETRIARY,
  lineHeight: '15px !important',
}));

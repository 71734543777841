import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useScrollbarPosition } from '@rumblefish/ui/hooks/useScrollbarPosition';
import { Button, Section } from '@rumblefish/ui/Rumblefish23Theme';
import parse from 'html-react-parser';
import type { LegacyRef } from 'react';
import React from 'react';

import { Line } from './Line';
import type { OfferBoxProps, OfferDataProp, OfferProps } from './Offer.types';
import {
  StyledStack,
  StyledOfferBox,
  StyledOneElement,
  StyledTextWrapper,
  StyledRightWrapper,
  StyledOffersWrapper,
} from './styles';

export const OfferBox = ({
  description,
  title,
  customData,
  linkTo,
  linkText,
  isActive,
}: OfferBoxProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const colorWhite = theme.palette.invertedContent?.PRIMARY;

  return (
    <StyledOfferBox isActive={isActive}>
      <Typography color={colorWhite} marginBottom="16px" variant="strong_600">
        {title}
      </Typography>
      <Typography color={colorWhite} variant="regular_300">
        {customData ? customData : parse(description || '')}
      </Typography>
      {linkTo && (
        <Button variant="link" link={linkTo}>
          {isMobile ? 'See more' : linkText || 'See more'}
        </Button>
      )}
    </StyledOfferBox>
  );
};

export const Offer = React.forwardRef(function Ref(
  {
    sx,
    oneElementTitle,
    oneElementSubtitle,
    oneElementDescription,
    descMarginBottom,
    title,
    description,
    data,
    CustomButton,
    ...props
  }: OfferProps & OfferDataProp,
  ref: LegacyRef<HTMLDivElement>
) {
  const theme = useTheme();

  const { containerRef, activeThreshold } = useScrollbarPosition();

  const colorWhite = theme.palette.invertedContent?.PRIMARY;

  const activeCompany = activeThreshold([0, 22, 48, 75]);

  const oneElementMode =
    oneElementTitle && oneElementSubtitle && oneElementDescription;

  return (
    <Section width="small">
      {!oneElementMode && (
        <>
          <div ref={ref} style={{ position: 'relative', top: '-80px' }} />
          <StyledStack {...props} sx={sx}>
            <StyledTextWrapper spacing={4} descMarginBottom={descMarginBottom}>
              <Typography color={colorWhite} variant="strong_600">
                {title}
              </Typography>
              <Typography color={colorWhite} variant="regular_400">
                {parse(description || '')}
              </Typography>
              {CustomButton}
            </StyledTextWrapper>
            <StyledRightWrapper ref={containerRef}>
              <Line />
              <StyledOffersWrapper spacing={18}>
                {data?.map((item, index) => {
                  return (
                    <OfferBox
                      key={index}
                      {...item}
                      isActive={index === activeCompany}
                    />
                  );
                })}
              </StyledOffersWrapper>
            </StyledRightWrapper>
          </StyledStack>
        </>
      )}
      {oneElementMode && (
        <>
          <div ref={ref} style={{ position: 'relative', top: '-80px' }} />
          <StyledOneElement spacing={4}>
            <Typography variant="heading3" color="white">
              {oneElementTitle}
            </Typography>
            <Typography variant="subtitle" color="white">
              {oneElementSubtitle}
            </Typography>
            <Typography variant="body2Small" color="white">
              {oneElementDescription}
            </Typography>
          </StyledOneElement>
        </>
      )}
    </Section>
  );
});

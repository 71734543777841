import { Typography, Stack, styled } from '@mui/material';
export const NavigatorWrapper = styled(Stack)(({ theme }) => ({
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
  },
}));

export const StyledCurrentPageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.blueColors?.PLUS_1,
}));

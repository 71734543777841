import { ChatBoxProps, Message } from './ChatBox.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { CSSTransition, Transition } from 'react-transition-group';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import {
  FingerPrintWrapper,
  PulseLoaderWrapper,
  StyledTransitionGroup,
  StyledWrapper,
} from './styles';
import { PausableSleep } from '../utils/pausableSleep';
import { ChatBoxRow } from '../ChatBoxRow';

export const ChatBox = ({ messages, useFingerPrint }: ChatBoxProps) => {
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const currentSleep = useRef<PausableSleep | null>(null);
  const firstAnimPlay = useRef<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [shouldShowSpinner, setShouldShowSpinner] = useState<boolean>(false);

  const doOnVisible = useCallback(async () => {
    if (currentSleep.current) {
      currentSleep.current.resume();
    }
    if (firstAnimPlay.current) {
      await setSleep(messages[0]);
      setCurrentIndex(0);

      firstAnimPlay.current = false;
    }
  }, [firstAnimPlay.current]);

  const doOnInvisible = useCallback(() => {
    if (currentSleep.current) {
      currentSleep.current.stop();
    }
  }, [currentSleep]);

  const showSpinnerAfterDelay = (delay: number) => {
    setTimeout(() => {
      setShouldShowSpinner(true);
    }, delay * 0.2);
  };
  const setSleep = async (message: Message) => {
    const sleep = new PausableSleep(message.delay);
    showSpinnerAfterDelay(message.delay);
    currentSleep.current = sleep;
    await sleep.promise;
    setShouldShowSpinner(false);
  };

  useEffect(() => {
    (async () => {
      if (currentIndex === null || currentIndex + 1 === messages.length) return;
      const newIndex = currentIndex + 1;
      const currentMessage = messages[newIndex];

      await setSleep(currentMessage);
      setCurrentIndex(newIndex);
    })();
  }, [currentIndex]);

  useEffect(() => {
    if (!chatBoxRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          doOnVisible();
        } else {
          doOnInvisible();
        }
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(chatBoxRef.current);
  }, []);

  return (
    <StyledWrapper ref={chatBoxRef}>
      <StyledTransitionGroup>
        {currentIndex !== null &&
          messages.slice(0, currentIndex + 1).map((msg, i) => (
            <CSSTransition key={msg.key} timeout={300} classNames="item">
              <ChatBoxRow message={msg} />
            </CSSTransition>
          ))}
      </StyledTransitionGroup>

      {shouldShowSpinner && (
        <PulseLoaderWrapper
          alignItems={
            messages[currentIndex === null ? 0 : currentIndex + 1]?.writerId ===
            1
              ? 'flex-start'
              : 'flex-end'
          }>
          <PulseLoader size={10} color="white" />
        </PulseLoaderWrapper>
      )}

      <Transition
        timeout={1000}
        in={
          currentIndex
            ? useFingerPrint && currentIndex + 1 === messages.length
            : false
        }>
        {(state) => (
          <FingerPrintWrapper className={`fingerPrint-${state}`}>
            <FingerprintIcon />
          </FingerPrintWrapper>
        )}
      </Transition>
    </StyledWrapper>
  );
};

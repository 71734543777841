import { Stack, Typography, styled } from '@mui/material';

export const StyledNumber = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

export const StyledCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

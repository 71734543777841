import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import { NameBanner } from '@rumblefish/ui/Rumblefish23Theme';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  GlowStack,
  TextStack,
  StyledText,
  StickyStack,
  HeighestStack,
  StyledAnimatedSpan,
  AbsoluteGlowWrapper,
  SectionNameBannerStack,
} from './styles';
import type { TextFillProps } from './TextFill.types';

export const TextFill = ({
  children,
  heighestStackStyle,
  styledTextStyle,
  nameBannerText,
  withoutGlow,
}: TextFillProps): ReactJSXElement => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [isWebKit, setIsWebkit] = useState<boolean>(false);
  const [isPhone, setIsPhone] = useState<boolean>(false);
  const webkitClassName = isWebKit ? 'UsingWebkit' : '';
  const threshold = 0.2;

  const getPositions = useCallback((): {
    currentPosition: number;
    endPosition: number;
  } => {
    const isMobile = theme.utils.isMobile();
    const isPhoneDevice = theme.utils.isPhone();
    const container = ref?.current?.getBoundingClientRect();
    if (!container || !textRef.current)
      return {
        currentPosition: 0,
        endPosition: 0,
      };
    const padding =
      (window.innerHeight - textRef.current.getBoundingClientRect().height) / 2;
    if (isMobile || theme.utils.isWebkit()) {
      if (window.innerHeight < container.height || isPhoneDevice)
        return {
          currentPosition:
            window.innerHeight -
            container.top -
            threshold * 0.8 * window.innerHeight -
            textRef.current?.offsetTop,
          endPosition:
            container.height +
            threshold * window.innerHeight +
            textRef.current?.offsetTop,
        };
      else
        return {
          currentPosition:
            window.innerHeight - container.top - 0.05 * window.innerHeight,

          endPosition:
            container.height +
            (window.innerHeight - container.height) / 2 -
            0.05 * window.innerHeight,
        };
    } else {
      return {
        currentPosition: threshold * window.innerHeight - container.top,
        endPosition: 1.6 * padding + container.height - window.innerHeight,
      };
    }
  }, [textRef.current, ref?.current]);

  const fillTextAnimation = useCallback(() => {
    if (!spanRef.current || (!isPhone && isWebKit)) return;
    // eslint-disable-next-line prefer-const
    let { endPosition, currentPosition } = getPositions();

    if (currentPosition < 0) currentPosition = 0;
    if (currentPosition > endPosition) currentPosition = endPosition;

    spanRef.current.style.backgroundSize = `${
      (currentPosition / endPosition) * 100
    }% 100%`;
  }, [textRef.current, ref?.current]);

  const animate = useCallback(() => {
    if (!spanRef.current || (!isPhone && isWebKit)) return;
    requestAnimationFrame(fillTextAnimation);
  }, [fillTextAnimation, isPhone, isWebKit]);

  useEffect(() => {
    setIsWebkit(theme.utils.isWebkit());
  }, []);

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef || (!isPhone && isWebKit)) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (!textRef.current || !currentRef) return;
        animate();
        window.addEventListener('scroll', animate);
      } else {
        window.removeEventListener('scroll', animate);
      }
    });
    observer.observe(currentRef);

    return () => observer.unobserve(currentRef);
  }, [ref.current]);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsPhone(window.innerWidth <= theme.breakpoints.values.sm);
      animate();
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [theme.breakpoints.values.sm]);

  return (
    <HeighestStack
      ref={ref}
      className={webkitClassName}
      sx={heighestStackStyle}>
      <AbsoluteGlowWrapper display={withoutGlow ? 'none' : 'flex'}>
        <StickyStack>
          <GlowStack className={webkitClassName} />
        </StickyStack>
      </AbsoluteGlowWrapper>
      <StickyStack ref={stickyRef} className={webkitClassName}>
        <TextStack>
          {nameBannerText && (
            <SectionNameBannerStack>
              <NameBanner
                type="section"
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    margin: theme.spacing(0, 0, 3, 0),
                    alignSelf: 'start',
                  },
                }}
                text={nameBannerText}
              />
            </SectionNameBannerStack>
          )}

          <StyledText
            className="TextFill"
            ref={textRef}
            variant="strong_700"
            paragraph
            sx={styledTextStyle}>
            <StyledAnimatedSpan className={webkitClassName} ref={spanRef}>
              {children}
            </StyledAnimatedSpan>
          </StyledText>
        </TextStack>
      </StickyStack>
    </HeighestStack>
  );
};

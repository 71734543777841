import React from 'react';
import { IAuthorBubble } from './AuthorBubble.types';
import {
  StyledImageWrapper,
  ContentWrapper,
  MainWrapper,
  TextWrapper,
  StyledName,
  StyledPosition,
  SocialsWrapper,
} from './styles';
import { EmailIcon, LinkedinIcon } from '../Icons';

export const AuthorBubble = ({ authorData }: IAuthorBubble) => {
  return (
    <MainWrapper>
      <StyledImageWrapper>
        <img src={authorData?.attributes?.Avatar?.data?.attributes?.url} />
      </StyledImageWrapper>
      <ContentWrapper>
        <TextWrapper>
          <StyledName variant="strong_600">
            {authorData?.attributes?.Name}
          </StyledName>
          <StyledPosition variant="regular_200">
            {authorData?.attributes?.Position}
          </StyledPosition>
        </TextWrapper>
        <SocialsWrapper>
          <EmailIcon />
          <LinkedinIcon />
        </SocialsWrapper>
      </ContentWrapper>
    </MainWrapper>
  );
};

import { styled } from '@mui/material';
import Plx from 'react-plx';
export const MainContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
}));

export const StyledWrapperPlx = styled(Plx)(() => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  visibility: 'visible',
  ['&.Plx--above']: {
    visibility: 'hidden',
  },
}));
export const StyledImagePlx = styled(Plx)(() => ({
  width: '100%',
  height: '100%',
  visibility: 'visible',
  backgroundSize: `100% 100%`,
  ['&.Plx--above']: {
    visibility: 'hidden',
  },
}));

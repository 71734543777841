import { Stack, Typography, styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
export const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  background: 'transparent',
  color: 'white',
  borderBottom: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,

  willChange: 'contents',
  '&:before': {
    display: 'none',
  },
  '&.MuiPaper-root': {
    willChange: 'margin-bottom, margin-top',
  },
  '& .MuiCollapse-root': {
    willChange: 'height, visibility',
  },
}));

export const StyledMainWrapper = styled(Stack)(({ theme }) => ({}));
export const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(
  ({ theme }) => ({
    padding: theme.spacing(2, 0),

    color: 'white',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      background: theme.palette.invertedSubtleAction?.ENABLED,
      borderRadius: '50%',
      padding: theme.spacing(0.6),
      color: theme.palette.greyBackgroundColor?.SURFACE,
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      background: theme.palette.greyBackgroundColor?.SURFACE,
      color: theme.palette.greyBackgroundColor?.INVERTED,
      transform: 'rotate(45deg)',
    },
  })
);

export const StyledMuiAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(0, 0, 2, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  })
);

export const StyledTitle = styled(Typography)<{
  shouldbegradient?: number;
  transitionduration: number;
  gradient?: string;
}>(({ theme, shouldbegradient, transitionduration, gradient }) => ({
  height: 'min-content',
  position: 'relative',
  marginRight: theme.spacing(2),
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent',

  '&:before': {
    position: 'absolute',
    content: 'attr(data-text)',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    color: theme.palette.invertedContent?.PRIMARY,
    transition: `opacity ${
      !shouldbegradient ? transitionduration : transitionduration * 2
    }s linear`,
    opacity: !shouldbegradient ? 1 : 0,
  },
  '&:after': {
    position: 'absolute',
    content: 'attr(data-text)',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    backgroundImage: gradient,
    transition: `opacity ${
      shouldbegradient ? transitionduration : transitionduration * 2
    }s linear`,
    opacity: shouldbegradient ? 1 : 0,
  },
}));
export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,

  ['a']: {
    color: theme.palette.invertedContent?.PRIMARY,
  },
}));

import { Stack, styled } from '@mui/material';

export const StyledTestimonialsStack = styled(Stack)<{
  parentpadding: number;
}>(({ theme, parentpadding }) => ({
  margin: `0 -${parentpadding}px`,
  WebkitMask:
    ' linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, #F2F2F2 23.15%, #FFF 79.4%, rgba(217, 217, 217, 0.00) 100%)',
  mask: ' linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, #F2F2F2 23.15%, #FFF 79.4%, rgba(217, 217, 217, 0.00) 100%)',
  [theme.breakpoints.down(800)]: {
    WebkitMask: 'none',
    mask: 'none',
  },

  ['& .slick-track']: {
    minHeight: '425px',
    [theme.breakpoints.down(1280)]: {
      minHeight: '425px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },

    display: 'flex !important',
    willChange: theme.utils.isWebkit() ? 'transform' : 'auto',
  },

  ['& .slick-slide']: {
    height: 'inherit !important',
    div: {
      height: '100%',
      maxHeight: '424px',
    },
    [theme.breakpoints.down(1280)]: {
      ['>div']: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
}));

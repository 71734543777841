import { Stack, styled, Typography } from '@mui/material';
const cardBlurDimension = '192px';
export const MainCardWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  padding: theme.spacing(3),
  position: 'relative',
  gap: theme.spacing(3),
  overflow: 'hidden',
  [theme.breakpoints.down('xl')]: {
    gap: theme.spacing(2.5),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2),
  },
  '&.UsingWebkit': {
    '&::after': {
      background: theme.palette.invertedBackgroundColor?.SUBTLE,
    },
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    minWidth: cardBlurDimension,
    minHeight: cardBlurDimension,
    borderRadius: '360px',
    background: theme.palette.invertedBackgroundColor?.MUTED,
    top: theme.spacing(-12),
    left: 0,
    willChange: 'filter',
    filter: 'blur(160px)',
  },
}));
export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.TETRIARY,
}));
export const HeadlineWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(1.5),
  },
}));
export const BubbleWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  aspectRatio: '1 / 1',
  '& .Bubble': {
    padding: '9px !important',
    minWidth: '56px !important',
    maxWidth: '64px !important',
    width: `${theme.utils.fluidSize({
      minSize: 56,
      maxSize: 64,
      minBp: 960,
    })} !important`,
    [theme.breakpoints.down('md')]: {
      padding: '5px !important',
    },
  },
  width: `${theme.utils.fluidSize({
    minSize: 56,
    maxSize: 64,
    minBp: 960,
  })}`,
  minWidth: '56px',
  maxWidth: '64px',
}));

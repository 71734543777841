import type { StackProps } from '@mui/material';
import React from 'react';

import { StyledDottedLine, StyledLineWrapper } from './styles';

export const Line = ({ ...props }: StackProps) => (
  <StyledLineWrapper {...props}>
    <StyledDottedLine />
  </StyledLineWrapper>
);

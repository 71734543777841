import { TErrorMessage } from '../../../../redux/reducers/types';
import { useCallback, useReducer } from 'react';

type ContactFormErrorsReducerState = {
  errors: TErrorMessage;
  isErrorOpen: boolean;
};

type AddErrorAction = {
  type: 'ADD_ERROR';
  payload: string;
};

type SetIsErrorOpen = {
  type: 'SET_IS_ERROR_OPEN';
  payload: boolean;
};

type ErrorActions = AddErrorAction | SetIsErrorOpen;

const getInitialState = (
  error?: TErrorMessage
): ContactFormErrorsReducerState => ({
  errors: error || {
    messages: [],
  },
  isErrorOpen: !!error?.messages,
});

const contactFormErrorsReducer = (
  state: ContactFormErrorsReducerState,
  action: ErrorActions
): ContactFormErrorsReducerState => {
  switch (action.type) {
    case 'ADD_ERROR':
      return {
        ...state,
        errors: {
          messages: [...state.errors.messages, action.payload],
        },
        isErrorOpen: true,
      };
    case 'SET_IS_ERROR_OPEN':
      return {
        ...state,
        isErrorOpen: action.payload,
      };
    default:
      return state;
  }
};

export const useContactFormErrors = (error?: TErrorMessage) => {
  const [state, dispatch] = useReducer(
    contactFormErrorsReducer,
    getInitialState(error)
  );
  const addError = useCallback(
    (message: string) => dispatch({ type: 'ADD_ERROR', payload: message }),
    [dispatch]
  );
  const setIsErrorOpen = useCallback(
    (isOpen: boolean) =>
      dispatch({ type: 'SET_IS_ERROR_OPEN', payload: isOpen }),
    [dispatch]
  );

  return {
    error: state.errors,
    isErrorOpen: state.isErrorOpen,
    addError,
    setIsErrorOpen,
  };
};

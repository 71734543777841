import { Stack, styled, Typography } from '@mui/material';

import keepScrolling from '../../../assets/png/keep-scrolling-circle.png';

import underlineStroke from '../../../assets/png/underline-stroke.png';
import { fluidSize } from '../../../theme/rumblefish23Theme/utils';
import { Button } from '../Button';
import { Link } from '../Link';
import { Section } from '../Section';

export const StyledHeroSection = styled(Section)({
  height: '100%',
});

export const StyledStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(12, 0),
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(10, 0),
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(8, 0),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(6, 0),
  },
}));

export const HeadingWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(10),
  padding: theme.spacing(10, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(10, 2),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 4),
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 1),
    gap: theme.spacing(4),
  },
}));

export const BorderStack = styled(Stack)(() => ({
  width: '100%',
  background: 'rgba(255, 255, 255, 0.2)',
  height: '1px',
  marginTop: '-1px',
}));

export const StyledMacWindow = styled(Stack)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  width: '75%',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  paddingTop: '42px',
  borderRadius: '24px',
  backdropFilter: 'blur(64px)',
  [theme.breakpoints.down('xl')]: {
    width: '75%',
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const MenuCircles = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: '15px',
  left: '20px',
  ['div']: {
    width: '12px',
    height: '12px',
    border: '1px solid #777B7C',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
}));

export const StyledUnderHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  b: {
    whiteSpace: 'nowrap',
    position: 'relative',
    ['&:after']: {
      content: "''",
      display: 'block',
      backgroundImage: `url(${underlineStroke})`,
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
      position: 'absolute',
      left: '0%',
    },
  },
}));

export const StyledHeroHeading = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 0),

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background:
    'linear-gradient(180deg, #FFFFFF 0%, rgba(217, 217, 217, 0.8) 100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',

  '& > ol': {
    position: 'relative',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    height: `calc(${fluidSize({ minSize: 32, maxSize: 64 })} - 2px)`,
    overflow: 'hidden',
  },

  '& > ol li': {
    transform: `translateY(calc(-100% * var(--word-counter) - 5%))`,
  },

  [theme.breakpoints.up('xl')]: {
    '& > ol': {
      height: '64px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > ol': {
      height: '62px',
    },
    '& > ol li': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '64px',
      lineHeight: '64px',
    },
  },
}));

export const StyledHeroDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
}));

export const StyledScheduleCallArea = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

export const StyledButtonWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(5),
  },
}));

export const StyledKeepScrolling = styled(Stack)(({ theme }) => ({
  height: theme.spacing(16),
  width: theme.spacing(16),
  backgroundImage: `url(${keepScrolling})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '95%',
  position: 'absolute',
  right: '10%',
  bottom: '8%',
  [theme.breakpoints.down('md')]: {
    position: 'static',
    marginLeft: theme.spacing(5.5),
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(4.5),
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(2, 0, 1, 1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  '.MuiStack-root': {
    backgroundColor: 'transparent !important',
    border: '1px solid white',
    boxShadow: `0px 0px 6px 4px ${theme.palette.redColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.redColors?.PLUS_1} inset !important`,
  },
}));

export const CompaniesStyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(7),
  marginTop: theme.spacing(6),
  textAlign: 'center',
  marginBottom: theme.spacing(16),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(15),
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(9),
  },
}));

export const StyledCompaniesHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));

import { Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(12, 0, 24),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  },
}));

export const StyledTextWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'descMarginBottom',
})<{ descMarginBottom?: boolean }>(({ theme, descMarginBottom }) => ({
  maxWidth: '490px',
  position: 'sticky',
  height: '750px',
  top: 120,
  marginBottom: descMarginBottom ? '300px' : 0,
  [theme.breakpoints.down('lg')]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '50px',
    height: 'auto',
    position: 'static',
    top: 'unset',
    width: '100%',
    maxWidth: 'unset',
  },
}));

export const StyledRightWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    width: '100%',
  },
}));

export const StyledOffersWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '476px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));

export const StyledOneElement = styled(Stack)(({ theme }) => ({
  color: 'white',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(22),
    paddingRight: theme.spacing(22),
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));

export const StyledOfferBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  opacity: isActive ? 1 : 0.15,
  transition: 'ease-in-out 0.2s',

  [theme.breakpoints.up('md')]: {
    '&:last-child': {
      minHeight: '750px',
    },
  },
}));

import { Stack, styled } from '@mui/material';

export const SliderStack = styled(Stack)(() => ({
  WebkitMask:
    'linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(217, 217, 217, 0.00) 100%)',
  mask: 'linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, rgba(255, 255, 255) 50%, rgba(217, 217, 217, 0.00) 100%)',
  overflow: 'hidden',
}));

export const SliderTrack = styled(Stack)<{
  companiesquantity: number;
}>(({ theme, companiesquantity }) => ({
  [`@keyframes move`]: {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(-50%)',
    },
  },
  willChange: 'transform',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  animation: `move 45s linear infinite`,
  MozAnimation: `move 45s linear infinite`,
  width: `calc((100% / ${companiesquantity}) * ${companiesquantity * 2})`,
  [theme.breakpoints.down('md')]: {
    width: `calc((100% / ${5}) * ${companiesquantity * 2})`,
  },
  [theme.breakpoints.down('sm')]: {
    width: `calc((100% / ${3}) * ${companiesquantity * 2})`,
  },
}));

export const SlideElementStack = styled(Stack)<{
  companiesquantity: number;
}>(({ theme, companiesquantity }) => ({
  width: `calc((100% / (${companiesquantity} * 2)))`,
  display: 'inline-block',
}));

export const StyledImage = styled('img')<{ shouldInvert?: boolean }>(
  ({ theme, shouldInvert }) => ({
    filter:
      theme.palette.type === 'dark'
        ? shouldInvert
          ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(102%) contrast(103%)'
          : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(102%) contrast(103%)'
        : '',
    width: '90%',
    objectFit: 'contain',
    aspectRatio: '3.5/1',
    userSelect: 'none',
    maxWidth: '140px',
    color: 'red',
  })
);

import { Stack, styled, Typography } from '@mui/material';

import { Button } from '../Button';

export const StyledAbsoluteContainer = styled(Stack)(({ theme }) => ({
  width: '230px',
  padding: theme.spacing(1.5, 0, 1.5, 1.5),
  position: 'fixed',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  zIndex: 10,
  right: 0,
  top: '30%',
  overflow: 'hidden',
  [theme.breakpoints.down('xl')]: {
    width: '210px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '165px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    alignItems: 'center',
    height: '130px',
    padding: theme.spacing(3, 3, 0, 3),
    bottom: 0,
    top: 'auto',
  },
  '&.Visible': {
    zIndex: 10,
  },
  '&.NotVisible': {
    zIndex: 0,
  },
}));

export const StyledLinkButton = styled(Button)(({ theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'start',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

export const StyledLgButton = styled(Button)(({ theme }) => ({
  display: 'none',
  width: '100%',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'start',
    div: {
      padding: '4px 0 !important',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledMotionDiv = styled(Stack)(({ theme }) => ({
  transition: 'transform 0.5s',
  transform: 'translateX(100%)',
  width: '100%',
  borderRadius: theme.spacing(1, 0, 0, 1),
  display: 'flex',
  overflow: 'hidden',
  padding: '1px 0px 1px 1px',
  background:
    ' radial-gradient(168.91% 117.40% at -0.00% 100.00%, rgba(255, 255, 255, 0.10) 0%, rgba(249, 39, 127, 0.00) 100%), rgba(255, 255, 255, 0.01)',

  [theme.breakpoints.down('md')]: {
    width: '55%',
    borderRadius: theme.spacing(1, 1, 0, 0),
    padding: '1px 1px 0px 1px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
  '&.Visible': {
    [theme.breakpoints.up('md')]: {
      transform: 'translateX(0%)',
    },
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(0%)',
    },
  },
  '&.NotVisible': {
    [theme.breakpoints.up('md')]: {
      transform: 'translateX(100%)',
    },
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100%)',
    },
  },
}));

export const StyledMainContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  display: 'flex',
  borderRadius: theme.spacing(1, 0, 0, 1),
  gap: theme.spacing(3),
  overflow: 'hidden',
  background: '#121B1C',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'row',
    gap: theme.spacing(0),
    padding: theme.spacing(0),
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledContentSection = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexDirection: 'column',
  padding: theme.spacing(0, 1.5, 2, 1.5),
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(1, 1),
    gap: theme.spacing(2),
  },

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0),
    padding: theme.spacing(1, 1),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1),
  },
}));

export const StyledBannerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  lineHeight: '25px !important',
}));

export const StyledBannerImage = styled('img')(({ theme }) => ({
  borderImage: 'fill 0 linear-gradient(#0001,#000)',
  width: '100%',
  aspectRatio: '1/1.22',
  mask: 'linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255) 80%, rgba(255, 255, 255) 100%)',
  [theme.breakpoints.down('md')]: {
    mask: 'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255) 80%, rgba(255, 255, 255) 100%)',
    width: '50%',
    maxWidth: '105px',
  },
}));

import { Box, Link, Stack, useTheme } from '@mui/material';
import { Checkbox, InputField } from '@rumblefish/ui/Rumblefish23Theme';
import React, { useEffect, useRef, useState } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';
import LoadingIcon from '../../../assets/svg/RumbleFish23Theme/LoadingFormIcon.svg';
import type { ISendData } from '../../../hooks/useForm/types';
import { useForm } from '../../../hooks/useForm/useForm';
import AlertSnackbar from '../../AlertSnackbar';
import type { ContactFormProps } from './ContactForm.types';
import { contactFormFields } from './FieldsData';
import {
  StyledLoadingIcon,
  StyledStack,
  StyledButtonWrapper,
  StyledButton,
  StyledRecaptcha,
  CheckboxLabelText,
} from './styles';
import { TErrorMessage } from '../../../../redux/reducers/types';
import { useContactFormErrors } from './ContactForm.reducer';

const LinkLabel = ({ color }: { color?: string }) => {
  const theme = useTheme();
  return (
    <CheckboxLabelText variant="regular_200">
      I accept Rumble Fish{' '}
      <Link
        href="/privacy-policy"
        underline={'none'}
        color={color ? color : theme.palette.blueColors?._0}
        target="_blank">
        Privacy Policy
      </Link>
    </CheckboxLabelText>
  );
};

export const ContactForm = ({
  error: baseError,
  color,
  ...props
}: ContactFormProps) => {
  const theme = useTheme();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { addError, error, isErrorOpen, setIsErrorOpen } =
    useContactFormErrors(baseError);

  const { data, methods, loading } = useForm(
    contactFormFields,
    `${process.env.BACKEND_API}/v1/contact`,
    true,
    () => captchaRef.current?.reset(),
    () => {
      addError(`Please contact us on e-mail hello@rumblefish.pl`);
    }
  );

  const dataToSend: ISendData = {
    fullName: data.fullName.text,
    email: data.email.text,
    subject: 'Contact Form',
    message: data.communication.checked
      ? `I would like to receive communication from the team. ${data.message.text}`
      : data.message.text,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const buttonColor = theme.palette?.[`${color}Colors`]?.PLUS_2;

  return (
    <StyledStack direction="column" justifyContent="space-between" {...props}>
      <Box sx={{ zIndex: 1 }}>
        <Stack gap={4}>
          <InputField
            size="small"
            label="Full Name"
            onChangeEvent={(e) => methods.setInputField('fullName', e)}
            value={data.fullName.text}
            disabled={loading}
            errorMessage={data.fullName.errorMessage}
            variant="filled"
            inputId={contactFormFields.fullName.id}
          />

          <InputField
            size="small"
            label="Bussiness Email"
            onChangeEvent={(e) => methods.setInputField('email', e)}
            value={data.email.text}
            disabled={loading}
            errorMessage={data.email.errorMessage}
            variant="filled"
            inputId={contactFormFields.email.id}
          />
          <InputField
            size="small"
            label="Message"
            onChangeEvent={(e) => methods.setInputField('message', e)}
            value={data.message.text}
            disabled={loading}
            errorMessage={data.message.errorMessage}
            variant="filled"
            inputId={contactFormFields.message.id}
          />
        </Stack>
        <Stack>
          <Checkbox
            color={buttonColor}
            label={
              <CheckboxLabelText variant="regular_200">
                I wish to receive Rumble Fish email communication.
              </CheckboxLabelText>
            }
            onChangeEvent={() => methods.setCheckBoxField('communication')}
            value={data.communication.checked}
            disabled={loading}
            error={data.communication.isError}
            checkboxId={contactFormFields.communication.id}
          />

          <Checkbox
            color={buttonColor}
            label={<LinkLabel color={buttonColor} />}
            onChangeEvent={() => methods.setCheckBoxField('policy')}
            value={data.policy.checked}
            disabled={loading}
            error={data.policy.isError}
            checkboxId={contactFormFields.policy.id}
          />
        </Stack>

        <StyledRecaptcha
          sitekey={process.env.CAPTCHA_KEY as string}
          onChange={(value) => methods.setCaptcha(value)}
          ref={captchaRef}
        />
      </Box>
      <StyledButtonWrapper>
        <StyledButton
          color={buttonColor}
          sx={{
            cursor: loading ? 'default' : 'pointer',
            '&:hover': {
              div: {
                pointerEvents: loading ? 'none' : 'auto',
              },
            },
          }}
          onClick={() => methods.submitForm(dataToSend)}>
          {loading ? (
            <StyledLoadingIcon src={LoadingIcon} alt="Loading..." />
          ) : (
            'Send your message'
          )}
        </StyledButton>
      </StyledButtonWrapper>
      {error?.messages.map((msg, idx) => (
        <AlertSnackbar
          key={idx}
          openErrorMessage={isErrorOpen}
          setOpenErrorMessage={setIsErrorOpen}
          errorMessage={msg}
        />
      ))}
    </StyledStack>
  );
};

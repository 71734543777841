import type { FormControlProps } from '@mui/material';
import type { ChangeEvent } from 'react';

export interface InputFieldProps extends FormControlProps {
  label?: string;
  errorMessage?: string;
  value: string;
  onChangeEvent: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  rows?: number;
  variant?: 'outlined' | 'filled';
  inputId: string;
}

const prefix = 'InputField';

export const inputFieldClasses = {
  outlined: `${prefix}-outlined`,
  filled: `${prefix}-filled`,
  navy: `${prefix}-navyMode`,
  light: `${prefix}-lightMode`,
  dark: `${prefix}-darkMode`,
};

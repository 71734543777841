import { FormControl, InputBase, InputLabel, styled } from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.redColors?.PLUS_1,
  },

  '& .Mui-disabled': {
    color: ` ${theme.palette.invertedContent?.PRIMARY} !important`,
    input: {
      color: ` ${theme.palette.invertedContent?.SECONDARY} !important`,
      WebkitTextFillColor: 'none',
    },
  },
  minHeight: theme.spacing(10),
}));
export const StyledInput = styled(InputBase)(({ theme, error }) => ({
  transition: '0s !important',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.invertedOutlineAction?.ENABLED,

  '& .MuiInputBase-input': {
    position: 'relative',
    color: theme.palette.invertedContent?.SECONDARY,
    fontFamily: 'Satoshi',
    fontWeight: 400,
    letterSpacing: '0.2px',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    lineHeight: '24px',
    fontSize: '14px',

    [theme.breakpoints.down(`md`)]: {
      minWidth: `unset`,
    },
    '&:-webkit-autofill ': {
      transition: ` background-color 600000s 0s, color 600000s 0s;`,
    },
    '&:-webkit-autofill:hover ': {
      transition: ` background-color 600000s 0s, color 600000s 0s;`,
    },
  },
}));

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  transition: '0s !important',
  fontFamily: 'Satoshi',
  fontWeight: 400,
  letterSpacing: '0.2px',
  userSelect: 'none',
  WebkitUserSelect: 'none',
  WebkitTouchCallout: 'none',
  lineHeight: '24px',
  fontSize: '14px',
  transform: 'none !important',
  position: 'static',
  color: theme.palette.invertedContent?.PRIMARY,
  width: '100%',
  '&.Mui-focused': {
    color: theme.palette.invertedContent?.PRIMARY,
  },
}));
export const StyledError = styled(InputLabel)(({ theme }) => ({
  transition: '0s !important',
  fontFamily: 'Satoshi',
  fontWeight: 400,
  letterSpacing: '0.2px',
  userSelect: 'none',
  WebkitUserSelect: 'none',
  WebkitTouchCallout: 'none',
  lineHeight: '24px',
  fontSize: '14px',
  transform: 'none !important',
  position: 'static',
  color: theme.palette.redColors?.PLUS_1,
  '&.Mui-focused': {
    color: theme.palette.redColors?.PLUS_1,
  },
}));
